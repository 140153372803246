/*
 * Copyright 2021 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../../../global/common";

.configuration-type-container > li {
  display:     flex;
  width:       415px;
  font-weight: 600;
}

.configuration-type-container > li > label {
  width:        230px;
  margin-right: 35px;
}

.template-wrapper {
  display: flex;
}

.template-link {
  line-height: 85px;
  margin:      0 0 0 30px;
  cursor:      pointer;
}

.template-link.disabled {
  opacity: 0.75;
  cursor:  not-allowed;

  &:hover {
    color:      $icon-color;
    background: $icon-bg;
  }
}


/*
 * Copyright 2021 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../../../../../global/common";

.run-if-checkbox-wrapper {
  display: flex;
  margin:  -10px 0 0 0;

  label {
    padding-right: 25px;
  }
}

.run-if-condition-help-text {
  margin-top:    -20px;
  margin-bottom: 20px;
  font-size:     13px;
  color:         $go-disabled;
}

/*
 * Copyright 2021 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../../global/common";
@import "index";

.btn-secondary {
  @include button($background-color: $white);

  color: $btn-primary;
  border: 1px solid $btn-primary;

  &:hover {
    color: $white;
    background-color: darken($btn-primary, 10%);
    border-color: darken($btn-primary, 10%);
  }
}

.btn-cancel {
  border: 1px solid $btn-reset;
  &:hover {
    background-color: $btn-reset;
    color: $white;
  }
}

/*
 * Copyright 2021 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 @import "../../global/common";

 .all-key-vals {
  border-collapse: collapse;

  tbody {
    td {
      vertical-align: top; // this way, when errors appear below the text fields, they keep aligned
      margin: 0;
      padding: 5px 0;
      padding-right: 5px;
    }

    td:last-child {
      padding-right: 0;
    }

    td:first-child {
      padding-right: 20px;
    }
  }

  .default-headers {
    th {
      padding: 0 0 10x;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .add-more {
    padding: 15px 0 0;
  }

  .field-group {
    margin-bottom: 0;
  }
}

/*
 * Copyright 2021 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import '../views/global/common';

.main-container {
  margin:      0 30px 50px;
  padding-top: 110px;
}

.page {
  height: 100%;
}

.pagewrap {
  min-height: 100%;
  @media(min-width: $screen-md) {
    margin-bottom: -94px;
  }
  @media(min-width: $screen-lg) {
    margin-bottom: -76px;
  }
}

.pagewrap:after {
  content: "";
  display: block;

}

.sitefooter, .pagewrap:after {
  @media(min-width: $screen-md) {
    height: 94px;
  }
  @media(min-width: $screen-lg) {
    height: 76px;
  }
}

